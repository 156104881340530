// extracted by mini-css-extract-plugin
export var background = "index-module--background--f7050";
export var description = "index-module--description--dc683";
export var frontpage = "index-module--frontpage--edd02";
export var icons = "index-module--icons--2c2fb";
export var name = "index-module--name--adab4";
export var profile = "index-module--profile--2f8f7";
export var surname = "index-module--surname--a814d";
export var title = "index-module--title--56ee9";
export var titleCard_ = "index-module--titleCard_--52e10";
export var typewriter = "index-module--typewriter--d6249";
export var typewriterr = "index-module--typewriterr--35efa";