import React from "react";
import ReactDOM from "react-dom";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { useMouse } from "react-use";
import profileImg from "../images/profile.png";
import github from "../images/icon-github.png";
import gitlab from "../images/icon-gitlab.jpg";
import lattes from "../images/icon-lattes.png";
import {
  background,
  frontpage,
  titleCard_ as titleCard,
  title,
  name,
  surname,
  icons,
  description,
  profile,
} from "./index.module.scss";

const Index = ({ data }) => {
  const ref = React.useRef();
  const { elX, elY } = useMouse(ref);
  return (
    <>
      <Layout
        className={frontpage}
        background={
          <div
            ref={ref}
            className={background}
            style={{
              "--mouseX": `${elX}px`,
              "--mouseY": `${elY}px`,
            }}
          >
            <div />
            <div />
            <div />
          </div>
        }
      >
        <section className={titleCard}>
          <header className={title}>
            <div className={name}>rafael</div>
            <div className={surname}>gonçalves</div>
          </header>
          <img className={profile} src={profileImg} alt="" />
          <p className={description}>
            Mestrando em Sociologia pelo IFCH/Unicamp com pesquisa na área de
            sociologia da tecnologia. Graduado em Engenharia Elétrica pela
            FEEC/Unicamp. Programador entusiasta do movimento software livre.
            Artista amador.
          </p>
          <div className={icons}>
            <a href="https://github.com/rafaelgoncalves8">
              <img src={github} alt="Github" title="Github" />
            </a>
            <a href="https://gitlab.com/rafaelgoncalves">
              <img src={gitlab} alt="Gitlab" title="Gitlab" />
            </a>
            <a href="http://lattes.cnpq.br/3812164161985946">
              <img
                src={lattes}
                alt="Currículo Lattes"
                title="Currículo Lattes"
              />
            </a>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Index;
