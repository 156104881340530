import React from "react";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import "/src/static/styles.scss";
import { content, background as bg } from "./Layout.module.scss";

const Header = () => {
  const menuItems = [
    {
      label: "Maquinações",
      link: "https://maquinacoes.rafaelg.net.br/",
      title:
        "Experimentações teóricas, fichamentos e outros comentários descartáveis",
    },
    // {
    //   label: "Sobre",
    //   link: "/sobre",
    // },
    // {
    //   label: "Produção",
    //   link: "/producao",
    // },
    // {
    //   label: "Genuary 2022",
    //   link: "https://rafaelgoncalves.gitlab.io/genuary-2022",
    // },
    // {
    //   label: "blog",
    //   link: "/blog",
    // },
  ];
  return (
    <header>
      <ul role="menubar">
        {menuItems.map(({ label, link, ...rest }, idx) => (
          <li key={`${label}-${idx}`}>
            <Link to={link} {...rest}>
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </header>
  );
};

const Layout = React.forwardRef(
  ({ className, header, children, background }, ref) => {
    const data = useStaticQuery(graphql`
      query layoutQuery {
        site {
          siteMetadata {
            title
            lang
            author
            description
          }
        }
      }
    `);
    return (
      <>
        <Helmet
          titleTemplate={`%s | ${data.site.siteMetadata.title}`}
          defaultTitle={data.site.siteMetadata.title}
        >
          <html lang={data.site.siteMetadata.lang} />
          <meta charSet="utf-8" />
          <meta name="author" content={data.site.siteMetadata.author} />
          <meta
            name="description"
            content={data.site.siteMetadata.description}
          />
          <meta
            name="icon"
            type="image/x-icon"
            href={data.site.siteMetadata.icon}
          />
        </Helmet>
        <div className={bg} id="background" ref={ref}>
          {background}
        </div>
        <div className={content}>
          <Header />
          <main className={className}>{children}</main>
          {/* <Footer /> */}
        </div>
      </>
    );
  }
);

export default Layout;
